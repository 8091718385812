import Container from '../components/container'
import Header from '../components/header'
import Pages from '../enums/pages'
import Background, { Raised } from '../components/background';
import Wrapper from '../components/centered-wrapper'
import styled from '@emotion/styled'
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Component } from 'react'
import ReactGA from '../components/ga';

const PortfolioContainer = styled.div`
    width: 70%;
    max-width: 1200px;
    background-color: transparent;
    height: 60%;
    z-index: 5;
    display: flex;
    flex-direction: column;
`

const Projects = styled.div`
    display: flex
`

const ProjectContainer = styled.div`
    padding: 15px;
    width: 33%;
    height: 300px;
    opacity: 0;
    transition: opacity 300ms;
`

const ProjectLink = styled.a`
    :link {
        color: rgba(255,255,255,0.7);
    }
    :visited {
        color: rgba(255,255,255,0.7);
    }
    :hover {
        color: rgba(255,255,255,1);
    }
`

const ProjectDescription = styled.div`
    margin-top: 5px;
    color: rgba(255,255,255,0.7);
    text-align: center;
    font-style: italic;
`

const images = graphql`
query {
    personal: file(relativePath: { eq: "projects/personal.png" }) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    travlog: file(relativePath: { eq: "projects/travlog.png" }) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    wedding: file(relativePath: { eq: "projects/wedding.png" }) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    congeewong: file(relativePath: { eq: "projects/congee-wong.png" }) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    centralreach: file(relativePath: { eq: "projects/centralreach.png" }) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    codegem: file(relativePath: { eq: "projects/codegem.png" }) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
}`

const projects = [
    {
        name: 'Personal Site',
        url: 'http://www.jasonbgao.com',
        image: 'personal',
        description: 'Built with GatsbyJS and AWS services.'
    },
    {
        name: 'Travlog App',
        url: 'https://app.travlog.io/public/jj689',
        image: 'travlog',
        description: 'Built with React and AWS services (Elasticache, AppSync, DynamoDB, API Gateway, EC2, etc...)'
    },
    {
        name: 'Christy & Jason\'s Wedding',
        url: 'https://www.christyandjason.com',
        image: 'wedding',
        description: 'Built with GatsbyJS, Google Sheets, and AWS services.'
    },
    {
        name: 'Congee Wong',
        url: 'https://congee-wong.com',
        image: 'congeewong',
        description: 'Built with Shopify.'
    }
]

const experience = [
    {
        name: "CentralReach",
        url: "https://centralreach.com",
        image: "centralreach",
        year: "2013 - 2020",
        description: "SaaS platform designed for management behavioral health practices. React, KnockoutJs, Typescript, SQL Server, C#, .net, AWS Services (RDS, DynamoDb, Elasticache, Elasticsearch)"
    },
    {
        name: "CodeGem",
        url: "https://codegem.app",
        image: "codegem",
        year: "2021 - present",
        description: "Building real time collaborative documents and analytics platform for engineering teams. React, NodeJs, Typescript, Serverless, Websockets, AWS Services (RDS, Lambda, DynamoDb, Elasticache, ECS Fargate, Cloudfront, etc...)"
    }
]

class Portfolio extends Component<any, { mounted: boolean }> {
    constructor(props) {
        super(props)
        this.state = {
            mounted: false
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ mounted: true }), 50)
    }

    render() {
        let style = this.state.mounted ? { opacity : 1} : {};

        return (
            <Container>
                <Header page={Pages.Portfolio} />
                <Wrapper>
                    <PortfolioContainer>
                        <h2 style={{ color: 'rgba(255,255,255,0.9)', textAlign: 'center' }}>Portfolio</h2>
                        <Projects>
                            { projects.map(p => (
                                <ProjectContainer style={style} key={p.name}>
                                    <div style={{ width: '100%', boxShadow: '1px 1px 5px rgba(0,0,0,0.3)' }}>
                                        <StaticQuery
                                                query={images}
                                                render={data => <GatsbyImage image={data[p.image].childImageSharp.gatsbyImageData} alt="Project Photo" /> }
                                        />
                                    </div>
                                    <div style={{ textAlign: 'center', marginTop: '10px', color: 'white' }}>
                                        <ProjectLink href={p.url}>{p.name}</ProjectLink>
                                    </div>
                                    <ProjectDescription>
                                        {p.description}
                                    </ProjectDescription>
                                </ProjectContainer>
                            )) }
                        </Projects>
                        <h2 style={{ color: 'rgba(255,255,255,0.9)', textAlign: 'center' }}>Experience</h2>
                        <Projects>
                        { experience.map(p => (
                                <ProjectContainer style={style} key={p.name}>
                                    <div style={{ width: '100%', boxShadow: '1px 1px 5px rgba(0,0,0,0.3)' }}>
                                        <StaticQuery
                                                query={images}
                                                render={data => <GatsbyImage image={data[p.image].childImageSharp.gatsbyImageData} alt="Project Photo" /> }
                                        />
                                    </div>
                                    <div style={{ textAlign: 'center', marginTop: '10px', color: 'white' }}>
                                        <ProjectLink href={p.url}>{p.name}</ProjectLink>
                                    </div>
                                    <div style={{ textAlign: 'center', marginTop: '10px', color: 'rgba(255,255,255,0.7)' }}>
                                        {p.year}
                                    </div>
                                    <ProjectDescription>
                                        {p.description}
                                    </ProjectDescription>
                                </ProjectContainer>
                            )) }
                        </Projects>
                    </PortfolioContainer>
                    <Background raised={Raised.Left} offset={0} />
                </Wrapper>
            </Container>
            )
    }
}

const PortfolioGa = ({ location }) => <ReactGA pathname={location.pathname}><Portfolio /></ReactGA>

export default PortfolioGa